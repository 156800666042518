@use 'styles/variables';
@use 'styles/functions';

.section {
  text-align: center;
  padding: 0;
}

.section div {
  padding: 0;
}

.paper {
  height: 75vh;
  width: 100%;
  border-radius: 0;
  margin: 0;
}

.block-img {
  width: 100%;
  height: 75vh;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      353.94deg,
      rgb(0 0 0 / 72.9%) 25.97%,
      rgb(0 0 0 / 39.2%) 60.75%,
      rgb(0 0 0 / 0%) 88.28%
    );
  }
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subtitle {
  padding: 0 functions.spacing(4);
}

@media only screen and (min-width: variables.$breakpoint-md) {
  .subtitle {
    padding: 0 functions.spacing(20);
  }

  .paper {
    border-radius: functions.spacing(2);
    margin: functions.spacing(4);
    overflow: hidden;
  }

  .section {
    padding-top: functions.spacing(6);
    padding-bottom: functions.spacing(6);
  }
}

@media only screen and (min-width: variables.$breakpoint-lg) {
  .block-img {
    &::before {
      background: linear-gradient(
        285.14deg,
        rgb(0 0 0 / 60%) -7.08%,
        rgb(0 0 0 / 58.2%) 8.5%,
        rgb(0 0 0 / 25.3%) 59.64%,
        rgb(0 0 0 / 0%) 82.29%
      );
    }
  }
}

@media only screen and (min-width: variables.$breakpoint-xl) {
  .subtitle {
    padding: 0 functions.spacing(55);
  }

  .paper {
    background-position: top center;
  }
}
