@use 'styles/variables';
@use 'styles/functions';

.hero-carousel-wrapper {
  height: 485px;
  padding: 0 !important; // the Carousel component has a more specific css that can’t be overridden
}

.card-hero {
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity 1s ease;
  background: black;
  &.card-hero-selected {
    transition: opacity 1s ease;
  }
}
.card-hero-block {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  border-radius: functions.spacing(1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-carousel-wrapper-indicators {
  // the Carousel component has a more specific css that can’t be overridden
  margin-top: functions.spacing(-4.125) !important;
  background-color: transparent !important;
  position: absolute !important;
}

.card-hero-image {
  opacity: 0.7;
}

.card-hero-content-bugline {
  padding-bottom: functions.spacing(1.5);
}

.card-hero-content {
  z-index: 1;
  position: relative;
  z-index: 1;
  height: 100%;
  max-width: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .card-hero-content-link {
    margin-top: functions.spacing(6);
  }
}
.hero-carousel-wrapper-controls {
  height: 40px !important;
  width: 40px !important;
}
.hero-carousel-wrapper-controls.control-left {
  left: calc(50% - 500px) !important;
}
/* Tablet (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-md) {
  .card-hero-content {
    max-width: 545px;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .card-hero-content {
    max-width: 1007px;
    width: 1007px;
  }
}

/* Ultra (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-xxl) {
  .card-hero-content {
    margin: 0;
    max-width: 100%;
    width: 100%;
    -webkit-box-shadow: inset 350px 0px 100px -30px rgba(0, 0, 0, 0.6),
      inset -350px 0px 100px -30px rgba(0, 0, 0, 0.6);
    box-shadow: inset 350px 0px 100px -30px rgba(0, 0, 0, 0.6),
      inset -350px 0px 100px -30px rgba(0, 0, 0, 0.6);
  }
}
