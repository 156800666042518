@use 'styles/functions';
@use 'styles/variables';

.faq-section {
  padding: functions.spacing(8) 0;
}

.accordion {
  &:not(:first-of-type) {
    padding-top: functions.spacing(4);
  }
}

.accordion-summary {
  padding-right: 0;
}

.accordion-summary-text {
  padding-right: 16px;
}

.button {
  height: 47px;
  width: 293px;
}

.expand-icon-selected {
  color: variables.$color-primary-achieve-blue;
}

.title {
  margin-bottom: functions.spacing(4);
}

@media screen and (min-width: variables.$breakpoint-lg) {
  .faq-section {
    padding: functions.spacing(11) 0;
  }

  .title {
    margin-bottom: functions.spacing(9);
  }
}
