@use 'styles/variables';
@use 'styles/functions';

.filter-grid {
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-right: functions.spacing(2);
  margin-left: functions.spacing(2);
  padding: 0 functions.spacing(1);
}

.label {
  margin-right: functions.spacing(5);
}

@media only screen and (min-width: variables.$breakpoint-sm) {
  .filter-grid {
    display: flex;
    direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
