@use 'styles/variables';
@use 'styles/functions';

.section {
  text-align: center;
  padding: functions.spacing(4) functions.spacing(2);
}

.title {
  color: variables.$color-neutral-white;
  line-height: 120%;
}

.subtitle {
  color: variables.$color-neutral-white;
  margin-top: functions.spacing(3.5);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .section {
    padding: functions.spacing(8) functions.spacing(4);
  }

  .subtitle {
    margin-top: functions.spacing(4);
  }
}
