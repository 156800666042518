@use 'styles/variables';
@use 'styles/functions';

.title {
  margin-top: functions.spacing(11);
  text-align: center;
  padding: 0 functions.spacing(5);
}

.filter {
  margin-top: functions.spacing(10);
  margin-bottom: functions.spacing(14);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: functions.spacing(2);
  margin-bottom: functions.spacing(10);
}

.news-card-grid {
  justify-content: center;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: functions.spacing(5);
}

.articles-container {
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  grid-gap: functions.spacing(5);
}

.article-grid {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: variables.$breakpoint-lg) {
  .article-grid {
    grid-column-end: span 6;
  }
}

@media only screen and (max-width: variables.$breakpoint-md) {
  .news-card-grid {
    justify-content: center;
    grid-gap: functions.spacing(3);
  }

  .article-grid {
    grid-column-end: span 12;
  }
}

@media only screen and (max-width: variables.$breakpoint-xs) {
  .news-card-grid {
    justify-content: center;
    grid-template-columns: 10px repeat(6, 1fr) 10px;
    grid-gap: functions.spacing(1);
  }

  .articles-container {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: functions.spacing(2);
  }

  .article-grid {
    grid-column-end: span 6;
  }
}
