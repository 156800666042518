@use 'styles/variables';
@use 'styles/functions';

.section {
  text-align: center;
}

.section div {
  padding: 0;
}

.paper {
  height: 70vh;
  width: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  margin: 0;
}

.desktop-hero-image {
  border-radius: 15px;
}

.breadcrumb-text {
  margin-bottom: functions.spacing(1);
  padding-bottom: functions.spacing(2);
  color: variables.$color-neutral-white;
  text-align: center;
}

.title-text {
  color: variables.$color-neutral-white;
  margin-bottom: functions.spacing(2);
  padding: 0 functions.spacing(10);
  text-align: center;
}

.subtitle-text {
  margin-bottom: functions.spacing(6);
  text-align: center;
  padding: 0 functions.spacing(6);
}

.subtitle-text a {
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
}

@media screen and (min-width: variables.$breakpoint-md) {
  .paper {
    height: 75vh;
  }

  .subtitle-text a {
    color: variables.$color-primary-achieve-blue;
  }

  .title-text {
    color: inherit;
    text-align: left;
    padding: 0;
    margin-bottom: functions.spacing(2);
  }

  .subtitle-text {
    text-align: left;
    padding: 0;
    margin-bottom: functions.spacing(4);
  }

  .breadcrumb-text {
    color: variables.$color-primary-achieve-blue;
    text-align: left;
    padding-top: functions.spacing(1);
    padding-bottom: functions.spacing(0);
    margin-bottom: functions.spacing(1);
  }

  .press-hero-container {
    padding: functions.spacing(2) functions.spacing(1);
  }

  .press-hero-section {
    padding: functions.spacing(2) functions.spacing(4) functions.spacing(4);
  }
}

@media screen and (min-width: variables.$breakpoint-lg) {
  .subtitle-text {
    margin-bottom: functions.spacing(6);
    padding-right: functions.spacing(9);
  }

  .press-hero-section {
    padding: functions.spacing(4) functions.spacing(8) functions.spacing(6);
  }

  .breadcrumb-text {
    padding-top: functions.spacing(3);
    margin-bottom: functions.spacing(3);
  }
}
