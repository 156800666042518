@use 'styles/variables';
@use 'styles/functions';

.card {
  align-items: center;
  background: variables.$color-neutral-background-default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: functions.spacing(6) functions.spacing(3);
  text-align: center;
  position: relative;

  &.card-recommended {
    padding: 0 functions.spacing(3) functions.spacing(6);
  }
}

.card-image {
  margin-bottom: functions.spacing(3);
}

.card-title {
  margin-bottom: functions.spacing(3);
}

.card-subtitle {
  margin-bottom: functions.spacing(3);
}

.card-copy-container {
  display: flex;
  flex-direction: column-reverse;
}

.card-recommended-items {
  border-radius: 8px;
  border: 1px solid variables.$color-neutral-grey-5;
  display: flex;
  flex-direction: column;
  margin: 0 0 functions.spacing(3);
  padding: functions.spacing(3);
  text-align: left;
  width: 100%;
}

.card-recommended-item {
  align-items: center;
  display: flex;

  & + .card-recommended-item {
    margin-top: functions.spacing(2);
  }
}

.recommended-item-icon {
  color: variables.$color-primary-achieve-blue;
  margin-right: functions.spacing(2);
}

@media only screen and (min-width: variables.$breakpoint-lg) {
  .card {
    border-radius: variables.$card-border-radius;
    min-height: 350px;
    z-index: 1;

    &[data-order-large='1'] {
      border-radius: variables.$card-border-radius 0 0 variables.$card-border-radius;
    }

    &[data-order-large='3'] {
      border-radius: 0 variables.$card-border-radius variables.$card-border-radius 0;
    }
  }

  .card,
  .card.card-recommended {
    padding: functions.spacing(3) functions.spacing(5);
  }

  .card-copy-container {
    display: flex;
    flex-direction: column;
  }

  .card-recommended {
    border: 2px solid variables.$color-primary-achieve-blue;
    z-index: 2;
  }
}
