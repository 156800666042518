@use 'styles/variables';
@use 'styles/functions';

//Mobile
.section {
  padding: functions.spacing(8) 0;
}

//Desktop
@media only screen and (min-width: variables.$breakpoint-lg) {
  .section {
    padding: functions.spacing(11) 0;
  }
}
