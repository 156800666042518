@use 'styles/variables';
@use 'styles/functions';

.home-by-the-numbers-container {
  padding: functions.spacing(8) 0 0;
}

.disclaimer {
  padding: functions.spacing(1);
  margin-top: functions.spacing(2);
  text-align: center;
  color: variables.$color-neutral-black;
}

@media only screen and (min-width: variables.$breakpoint-xs) {
  .disclaimer {
    padding: 0 functions.spacing(6);
  }
}

@media only screen and (min-width: variables.$breakpoint-md) {
  .home-by-the-numbers-container {
    padding: functions.spacing(11) 0 0;
  }

  .disclaimer {
    padding: 0 functions.spacing(24);
  }
}
