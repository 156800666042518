@use 'styles/variables';
@use 'styles/functions';

.product-hero-container {
  padding: 0;
}

@media screen and (min-width: variables.$breakpoint-lg) {
  .product-hero-section {
    padding: functions.spacing(4) functions.spacing(8) functions.spacing(8);
  }
}
