@use 'styles/variables';
@use 'styles/functions';

.card {
  margin-bottom: functions.spacing(7.5);
  width: 100%;

  .box-download {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .description-text {
    max-width: 279px;
  }

  .description-box {
    display: flex;
    flex-direction: column;

    .description-image {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;

      .image {
        border-radius: functions.spacing(1.25);
      }
    }
  }
}

@media only screen and (min-width: variables.$breakpoint-lg) {
  .card {
    .description-text {
      max-width: 385px;
    }

    .description-box {
      flex-direction: row;
    }
  }
}
