@use 'styles/functions';
@use 'styles/variables';

.section {
  background-color: variables.$color-primary-highlight-blue;
  padding: functions.spacing(8) 0;
}

.video-section-title-divider {
  p {
    text-align: center;
    margin: functions.spacing(2) 0 functions.spacing(5);
    padding: 0 functions.spacing(5);
  }
}

@media screen and (min-width: variables.$breakpoint-lg) {
  .section {
    padding: functions.spacing(11) 0;
  }

  .video-section-title-divider {
    p {
      padding: 0 functions.spacing(26);
    }
  }
}
