/*
Sunbeam variables are imported here and shouldn't be imported in any other
modules and all Sunbeam variables can be made available in a components
component.module.scss file by including this file like:

`@use styles/variables`
  NOTE: Be sure to use @use and not @import since doing so increases the bundle
  size while @use only loads a file once

- Using a variable:
  `color: variables.$color-neutral-background-medium;`

TODO: remove placeholder variables when Sunbeam exposes them in SCSS.
Sunbeam repository: https://bitbucket.org/billsdotcom_bills/sunbeam/src/main/
*/
@import '@ffn/sunbeam/dist/variables';

/* Spacing (replace when exposed in Sunbeam) */
$spacing: 8px;

/* Card component family border radius */
$card-border-radius: 20px;
$card-mobile-border-radius: 10px;

/* Animation timing */
$default-animation-timing: 250ms cubic-bezier(0.4, 0, 0.2, 1); // matches defaults for mui components

/* Colors to be replaced by sunbeam */
$color-paper-white: #e0e0e0;
$color-neutral-eighty: #313133;
$color-neutral-gray-eighty: #525b6d;

/* Maximum site width */
$maximum-site-width: 1920px;

/**
 * Constants based on grid specs in Ultramarine
 * https://www.figma.com/file/tn1r1b5fs14dD0kFLSk4mL/ULTRAMARINE-Achieve-Design-System?node-id=21%3A92
 */
$maximum-site-content-width: 1440px;

/* Grid gutter width constants */
$default-gutter-width: $spacing * 2; // 16px
$medium-and-up-gutter-width: $spacing * 4; // 32px
