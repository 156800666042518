@use 'styles/variables';
@use 'styles/functions';

.button {
  width: auto;
  padding: functions.spacing(1) functions.spacing(2) !important; //Once again styles do not apply
  color: variables.$color-neutral-white;
  position: relative;
}

.a {
  text-decoration: none;
  color: inherit;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.card {
  background: variables.$color-neutral-background-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: functions.spacing(5) functions.spacing(3);
  max-width: 343px;
  min-height: 340px;
  border: 1px solid variables.$color-error-text-disabled;
  border-radius: variables.$card-border-radius;
  text-align: center;
}

.card-image {
  margin-bottom: functions.spacing(2);
}

.card-title {
  padding: 0 functions.spacing(2);
  margin-bottom: functions.spacing(2);
}

.card-subtitle {
  padding: 0 functions.spacing(2);
  margin-bottom: functions.spacing(3);
}

@media only screen and (min-width: variables.$breakpoint-lg) {
  .card {
    max-width: 384px;
    padding: functions.spacing(7) functions.spacing(4);
  }

  .card-title {
    padding: functions.spacing(2) 0 0 0;
  }

  .card-subtitle {
    padding: 0 functions.spacing(1) 0 0;
    height: 120px;
  }
}
