@use 'styles/variables';
@use 'styles/functions';

.mad-lib-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mad-lib-paper {
  border-radius: variables.$card-mobile-border-radius;
  min-height: 389px;
  position: relative;
  width: 100%;
}

.mad-lib-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: functions.spacing(4);
}

.mad-lib-row {
  align-self: flex-start;
  margin-bottom: functions.spacing(2);
  text-align: left;
  width: 100%;

  &:last-child {
    margin-top: functions.spacing(4);
    text-align: center;
  }
}

.mad-lib-leading-text {
  margin-right: functions.spacing(1.5);
}

.mad-lib-progress-spinner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mad-libs-results {
  padding: functions.spacing(5) 0;
}

.mad-lib-results-header {
  padding: 0 functions.spacing(3);
  text-align: center;
}

.mad-libs-results-title {
  margin-bottom: functions.spacing(2);
  text-align: center;
}

.reset-link {
  cursor: pointer;
  display: inline-flex;
  margin-bottom: functions.spacing(2);

  span {
    margin-right: functions.spacing(1);
  }
}

.mad-libs-results-subtitle {
  margin-bottom: functions.spacing(4);
  text-align: center;
}

.mad-lib-results-grid-item {
  &:not(:first-child) {
    border-top: 1px solid variables.$color-primary-border-disabled;
  }

  &[data-mobile-hidden='true'] {
    display: none;
  }
}

.mad-lib-results-card {
  margin: 0 (2 * variables.$spacing);
}

.select-icon {
  fill: variables.$color-primary-achieve-blue;
  height: 24px;
  width: 24px;
}

.select-text {
  padding-right: functions.spacing(2);
  white-space: initial;
}

.mobile-expand-link {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-bottom: functions.spacing(1);
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .mad-lib-paper {
    border-radius: variables.$card-border-radius;
    min-height: 514px;
  }

  .mad-lib-form {
    padding: functions.spacing(11) functions.spacing(3) functions.spacing(9);
  }

  .mad-lib-row {
    align-self: initial;
    margin-bottom: functions.spacing(2);
    text-align: center;
  }

  .select-text {
    padding-right: functions.spacing(4);
    white-space: nowrap;
  }

  .select-icon {
    height: 48px;
    margin-top: functions.spacing(-1);
    width: 48px;
  }

  .mad-libs-results {
    padding: functions.spacing(5) functions.spacing(6) functions.spacing(4);
  }

  .mad-lib-results-header {
    padding: 0;
  }

  .mad-lib-results-grid-item {
    &:not(:first-child) {
      border-top: none;
    }

    &[data-mobile-hidden='true'] {
      display: block;
    }
  }

  .mobile-expand-link {
    display: none;
  }
}
