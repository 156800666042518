@use 'styles/variables';
@use 'styles/functions';

//Mobile styles
.closer-statement-section {
  background-color: variables.$color-primary-achieve-blue;
  color: variables.$color-neutral-white;
  text-align: center;
  padding: functions.spacing(7) 0 0;

  .outer-grid-container {
    justify-content: center;
    overflow: hidden;
  }

  .grid-item-text {
    order: 1;

    .text-container {
      .title {
        padding-bottom: functions.spacing(5);
        line-height: 140%;
      }

      .cta-container {
        :focus {
          outline: none;
          box-shadow: 0 0 0 3px variables.$color-neutral-white;
          overflow: visible;
        }
      }

      .button {
        &:hover {
          background-color: variables.$color-primary-neutral-blue;
        }

        background-color: variables.$color-primary-highlight-blue;
        color: variables.$color-primary-achieve-blue;
        padding: functions.spacing(1) functions.spacing(2);
        height: 48px;
        width: 274px;
        z-index: 1;
        margin: 2px;
      }

      .alt-cta {
        align-self: center;
        padding-top: functions.spacing(5);
      }
    }
  }

  .grid-item-image {
    order: 2;
    margin: functions.spacing(-7) functions.spacing(-4) functions.spacing(-3);

    .image-container {
      min-width: 360px;
      transform: translate(functions.spacing(0), functions.spacing(3));
    }
  }
}

/* Desktop (Xlarge or greater) styles */
@media screen and (min-width: variables.$breakpoint-xl) {
  .closer-statement-section {
    text-align: left;
    padding: functions.spacing(0) 0 0 functions.spacing(10);

    .outer-grid-container {
      justify-content: flex-start;
    }

    .grid-item-text {
      .text-container {
        padding: 0 0 functions.spacing(0);

        .title {
          padding-bottom: functions.spacing(4);
          line-height: 120%;
        }

        .cta-container {
          margin: 2px;
          display: flex;
        }

        .alt-cta {
          padding: 0 0 0 functions.spacing(5);
        }
      }
    }

    .grid-item-image {
      .image-container {
        margin: functions.spacing(0) 0 0 functions.spacing(0);
        transform: translate(functions.spacing(0), functions.spacing(2));
        max-width: 650px;
      }
    }
  }
}
