@use 'styles/variables';
@use 'styles/functions';

// Mobile styles, smaller than large breakpoint
.hero-section {
  margin: 0;
  padding: functions.spacing(5) functions.spacing(0);
}

.body-section {
  margin: 0;
  padding: 0 functions.spacing(2) functions.spacing(5);
}

.body-text-section {
  scroll-margin-top: functions.spacing(12);
  padding-bottom: functions.spacing(5);
}

.vertical-menu {
  padding: 0;
  position: sticky;
  top: functions.spacing(12);

  .item {
    padding: 0;
    white-space: normal;
    min-height: auto;
    background-color: variables.$color-neutral-background-default;

    a {
      border-left: 4px solid variables.$color-neutral-border-default;
      color: variables.$color-neutral-black;
      text-decoration: none;
      width: 100%;
      padding: functions.spacing(2) functions.spacing(3);
    }
  }

  .item a[data-active='true'] {
    border-color: variables.$color-primary-text-onlight;
    background-color: variables.$color-neutral-background-default;
    z-index: 1;
    color: variables.$color-primary-text-onlight;
  }
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: variables.$breakpoint-lg) {
  .hero-section {
    margin: 0;
    padding: functions.spacing(11) functions.spacing(14);
  }

  .body-section {
    margin: 0;
    padding: 0 functions.spacing(14) functions.spacing(10);
  }

  .item:hover a,
  .item:active a,
  .item:focus-within a,
  .item:focus a {
    border-color: variables.$color-primary-text-onlight;
    background-color: variables.$color-neutral-background-default;
    z-index: 1;
    color: variables.$color-primary-text-onlight;
  }
}
