@use 'styles/variables';
@use 'styles/functions';

.title-divider {
  margin-bottom: functions.spacing(8);
}

.title {
  text-align: center;
  padding: 0 functions.spacing(5);
}

.subtitle {
  text-align: center;
  margin: functions.spacing(2) 0 0;
  padding: 0 functions.spacing(5);
}

//Desktop
@media only screen and (min-width: variables.$breakpoint-lg) {
  .title-divider {
    margin-bottom: functions.spacing(11);
  }

  .subtitle {
    margin: functions.spacing(3) 0 0;
  }
}
