@use 'styles/variables';
@use 'styles/functions';

//Mobile styles
.about-closer-statement-section {
  background-color: variables.$color-primary-achieve-blue;
  color: variables.$color-neutral-white;
  text-align: center;
  padding: functions.spacing(5) 0 0;

  .outer-grid-container {
    justify-content: center;
    overflow: hidden;
  }

  .grid-item-image {
    order: 2;
    margin: functions.spacing(-16) functions.spacing(-10) 0;

    .image-container {
      min-width: 400px;
      transform: translate(functions.spacing(1), functions.spacing(16));
    }
  }

  .grid-item-text {
    order: 1;

    .text-container {
      padding: 0 functions.spacing(5) functions.spacing(2);

      .title {
        padding-bottom: functions.spacing(1);
      }

      .subtitle {
        padding-bottom: functions.spacing(3);
      }

      .button {
        background-color: variables.$color-primary-highlight-blue;
        color: variables.$color-primary-achieve-blue;
        padding: functions.spacing(1) functions.spacing(9);
      }
    }
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .about-closer-statement-section {
    text-align: left;
    padding: 0 functions.spacing(5);

    .outer-grid-container {
      justify-content: flex-start;
    }

    .grid-item-image {
      order: 1;
      margin: 0;

      .image-container {
        margin: 0 0 0 functions.spacing(2);
        min-width: 0;
        max-width: 620px;
        transform: translate(functions.spacing(-4), functions.spacing(6));
      }
    }

    .grid-item-text {
      order: 2;

      .text-container {
        padding: 0 0 0 functions.spacing(2);

        .title {
          padding-bottom: functions.spacing(4);
        }

        .subtitle {
          padding-bottom: functions.spacing(6);
          max-width: 530px;
        }
      }
    }
  }
}
