@use 'styles/variables';
@use 'styles/functions';

.card {
  width: 280px;
  height: 338px;
  position: relative;
}

.footer {
  width: 100%;
  height: 63px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$color-neutral-white;
  opacity: 0.8;
  padding: 0 functions.spacing(2);
  text-align: center;
}

.action {
  height: 100%;
  width: 100%;
}
