@use 'styles/variables';
@use 'styles/functions';

// Mobile styles, smaller than large breakpoint
.hero-section {
  margin: 0;
  padding: functions.spacing(5) functions.spacing(0);
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: variables.$breakpoint-lg) {
  .hero-section {
    margin: 0;
    padding: functions.spacing(11) functions.spacing(14);
  }
}
