.home-hero-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.home-hero-section {
  overflow: hidden;
}
