@use 'styles/variables';
@use 'styles/functions';

.section {
  padding: functions.spacing(8) 0;
}

.grid-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: functions.spacing(-2);

  &:last-child {
    margin-bottom: functions.spacing(-2);
  }

  > div + div {
    margin-top: functions.spacing(2);
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: functions.spacing(6);
}

// These hard coded button sizes should be in line with styles from Sunbeam.
// TODO - Check with Design
.button {
  width: 293px;
  height: 47px;
}

.typography {
  min-width: 105px;
  text-align: center;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .section {
    padding: functions.spacing(11) 0 functions.spacing(11);
  }

  .value-prop-title-divider {
    margin-bottom: functions.spacing(7);
  }

  .card-grid > div + div {
    margin-top: 0;
  }

  .container-grid {
    margin: 0 functions.spacing(4);
  }
}
