@use 'styles/variables';
@use 'styles/functions';

.section {
  //16px bottom section padding plus 48px margin from card above = 64px
  padding: functions.spacing(8) functions.spacing(0) functions.spacing(2);
}

.outer-grid-container {
  justify-content: center;
}

.grid-wrapper {
  max-width: 1080px;
}

.grid-item-container {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: variables.$breakpoint-lg) {
  .section {
    padding: functions.spacing(11) functions.spacing(14) functions.spacing(5);
  }
}
