@use 'styles/variables';
@use 'styles/functions';

.block-img {
  width: 100%;
  height: 180px;
  position: relative;
}

.text-center {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 functions.spacing(1);
  text-align: center;
  width: 100%;
}

.text-center p {
  width: 100%;
}

.text-heading {
  text-align: center;
  margin: functions.spacing(3.75);
}

.blue-box {
  background-color: variables.$color-primary-achieve-blue;
  color: variables.$color-neutral-white;
  width: 100%;
  overflow: hidden;
  min-height: functions.spacing(31);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.disclaimer {
  border-top: 1px solid variables.$color-neutral-grey-4;
  padding: functions.spacing(1);
  margin-top: functions.spacing(2);
  text-align: center;
  color: variables.$color-neutral-grey-4;
}

.hr {
  width: 88px;
  height: 0;
  border: 2px solid variables.$color-primary-achieve-blue;
  border-radius: 4px;
  margin-top: functions.spacing(11);
  margin-bottom: functions.spacing(2);
}

.box-media-text {
  overflow: hidden;
}

.box-media-text-disclaimer {
  overflow: hidden;
}

@media only screen and (min-width: variables.$breakpoint-md) {
  .text-center {
    height: 407px;
    text-align: left;
    padding: 0 functions.spacing(8);
  }

  .disclaimer {
    text-align: left;
  }

  .block-img {
    height: 407px;
  }
}

@media only screen and (min-width: variables.$breakpoint-xl) {
  .text-center {
    height: auto;
    text-align: left;
  }

  .disclaimer {
    padding: functions.spacing(3) functions.spacing(20) 0 0;
    margin-top: functions.spacing(8);
  }
}
