@use 'styles/variables';

.three-product-grid-container {
  flex-direction: column;
}

// Extra large viewport and up
@media only screen and (min-width: variables.$breakpoint-xl) {
  .three-product-grid-container {
    flex-direction: row;
  }
}
