@use 'styles/variables';
@use 'styles/functions';

.card {
  background-color: variables.$color-primary-highlight-blue;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  max-width: 350px;
  border-radius: 0;
  margin: functions.spacing(4) 0;
}

.image {
  border-radius: 10px;
  width: 310px;
}

.title-typography {
  padding: 0 functions.spacing(4);
  margin: functions.spacing(2) 0;
  text-align: center;
}

.text-typography {
  padding: 0 functions.spacing(4);
  margin: functions.spacing(2) 0;
  text-align: center;
}
