@use 'styles/variables';
@use 'styles/functions';

.carousel-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.carousel-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &.standard {
    justify-content: unset;
  }

  &.preview {
    justify-content: flex-start;
  }
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-container-item {
  flex-shrink: 0;
  scroll-snap-align: start;
  box-sizing: content-box;

  &.standard {
    width: 100%;
    height: 100%;
  }

  &.preview {
    width: 85%;
    margin: 0 variables.$spacing;
  }
}

.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}

.carousel-control {
  display: none;
}

.control-left {
  left: 1em;
}
.control-left-center {
  left: calc(50% - 450px);
}

.control-right {
  right: 1em;
}
.control-right-center {
  right: calc(50% - 450px);
}

.dot-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: variables.$color-primary-achieve-blue;
  z-index: 1;
  position: relative;
  margin-top: functions.spacing(4);
}

.dot-controls-dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  outline: none;
  background-color: variables.$color-primary-background-weak;
  cursor: pointer;
  border: none;
  margin: 0 variables.$spacing;
  padding: 0;
}

.dot-controls-dot-active {
  background-color: variables.$color-primary-background-default;
  opacity: 1;
}

/* iPad protrait or similar */
@media screen and (min-width: variables.$breakpoint-md) {
  .carousel-container-item.preview {
    width: 70%;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .carousel-wrapper {
    &.preview {
      padding-left: functions.spacing(12);
    }

    &.standard {
      padding-left: functions.spacing(3);
      padding-right: functions.spacing(3);
    }
  }

  .dot-controls {
    margin-top: functions.spacing(5);
  }

  .dot-controls-dot {
    height: 6px;
    width: 6px;
  }

  .dot-controls-dot-active {
    height: 6px;
    width: 6px;
  }

  .carousel-container {
    align-items: center;
  }

  .carousel-control {
    text-align: center;
    display: inherit;
    position: absolute;
    z-index: 2;
    top: calc(50% - 1em);
  }

  .carousel-container-item.preview {
    width: 85%;
    margin: 0 functions.spacing(2);

    &:last-child {
      margin-right: functions.spacing(19);
    }
  }
}
