@use 'styles/functions';
@use 'styles/variables';

.about-us-leadership-section {
  padding: functions.spacing(8) 0;
}

.item {
  display: flex;
  justify-content: center;
}

.collapsible {
  margin-top: functions.spacing(3);
}

.content-container {
  padding: functions.spacing(5) 0 0;
  display: flex;
  flex-direction: column;
}

.collapse-button {
  margin-top: functions.spacing(8);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: variables.$breakpoint-lg) {
  .about-us-leadership-section {
    padding: functions.spacing(11) 0;
  }

  .content-container {
    padding: functions.spacing(10) 0 0;
  }
}
