@use 'styles/variables';
@use 'styles/functions';

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.card {
  width: 100%;
  border-style: none;
  box-shadow: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-content {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding: functions.spacing(2);
  text-align: center;

  &:last-child {
    padding-bottom: functions.spacing(2);
  }
}

.image-grid {
  margin-bottom: functions.spacing(2);
}

.title-typography {
  margin-bottom: functions.spacing(2);
  text-align: center;
}

.title-typography-breakline {
  text-align: center;
}

.title-typography-breakline-container {
  text-align: center;
}
