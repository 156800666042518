@use 'styles/variables';
@use 'styles/functions';

//Mobile styles
.closer-statement-section {
  background-color: variables.$color-primary-achieve-blue;
  color: variables.$color-neutral-white;
  text-align: center;
  max-height: 520px;
  display: flex;
  justify-content: center;
}

.outer-grid-container {
  padding-top: functions.spacing(7);
  justify-content: center;
  overflow: hidden;
}

.grid-item-text {
  order: 1;
}

.title {
  padding-bottom: functions.spacing(3);
  line-height: 140%;
}

.button {
  margin: 0 functions.spacing(0.3);
  z-index: 1;
}

.alt-cta {
  align-self: center;
  padding-top: functions.spacing(5);
}

.image-container {
  min-width: 320px;
  transform: translate(functions.spacing(0), functions.spacing(2));
}

.grid-item-image {
  order: 2;
  margin: functions.spacing(-6) functions.spacing(-4) functions.spacing(-3);
}

/* Desktop (Xlarge or greater) styles */
@media screen and (min-width: variables.$breakpoint-xl) {
  .closer-statement-section {
    text-align: left;
    height: 339px;
  }

  .outer-grid-container {
    padding-top: 0;
    justify-content: flex-start;
    max-width: 920px;
    height: 339px;
    margin: auto;
  }

  .text-container {
    padding: 0;
    max-width: 450px;
  }

  .title {
    padding-bottom: functions.spacing(4);
  }

  .cta-container {
    margin: 2px;
    display: flex;
  }

  .alt-cta {
    padding: 0 0 0 functions.spacing(5);
  }

  .grid-item-image {
    margin: functions.spacing(-12) functions.spacing(-4) functions.spacing(-3);
  }
}
